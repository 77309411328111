import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './html.css';

const HTML = ({ content }) => {
  return <Container dangerouslySetInnerHTML={{ __html: content }} />;
};

HTML.propTypes = {
  content: PropTypes.object.isRequired,
};

export default HTML;
