import styled from 'styled-components';
import { color } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  .content {
    display: flex;
    margin-top: 40px;

    .column {
      width: 50%;

      h3 {
        position: relative;
        margin-left: 100px;
        font-size: 20px;
        line-height: 50px;
        font-weight: 400;
        color: ${color.black};

        &::after {
          content: '';
          display: block;
          position: absolute;
          left: -100px;
          top: 0;
          width: 50px;
          height: 50px;
          background-size: 100%;
        }

        &.wme::after {
          background-image: url('images/logo_wme.png');
        }

        &.caa::after {
          background-image: url('images/logo_caa.png');
        }

        &.lux::after {
          background-image: url('images/logo_lux.png');
        }
      }

      .cards {
        margin-left: 100px;

        .card {
          margin-top: 30px;

          h4 {
            font-size: 20px;
            line-height: 30px;
            font-weight: 800;
            color: ${color.black};
          }

          p.role {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 0px;
            color: ${color.black};
          }

          .contact {
            margin-top: 10px;

            p,
            a {
              margin: 0;
              padding: 0;
              font-size: 14px;
              line-height: 20px;
              color: ${color.black};
            }
          }
        }
      }
    }
  }

  ${MEDIA.PHONE`
    .content {
      display: block;
      margin-top: 40px;

      .column {
        width: 100%;

        h3 {
          position: relative;
          margin-left: 50px;
          font-size: 16px;
          line-height: 30px;

          &::after {
            left: -50px;
            width: 30px;
            height: 30px;
          }
        }

        .cards {
          margin-left: 50px;

          .card {
            margin-top: 20px;

            h4 {
              font-size: 16px;
              line-height: 20px;
            }

            p.role {
              font-size: 14px;
            }

            .contact {
              margin-top: 5px;
            }
          }
        }
      }
    }
  `};
`;
