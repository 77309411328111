import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Page from 'components/page';
import HTML from 'components/html';

const About = ({ data }) => {
  const page = data.cms.pages.edges[0].node;

  return (
    <Page contained>
      <h1>{page.title}</h1>
      <HTML content={page.content} />
    </Page>
  );
};

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    cms {
      pages(where: { title: "Contact" }) {
        edges {
          node {
            title
            content
          }
        }
      }
    }
  }
`;

export default About;
